
.el-breadcrumb {
    line-height: 40px;
}
.education-wrapper {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            padding: 30px;
        }
    }
}
.steps-wrapper {
    margin-top: 70px;
}
.create-content {
    margin: 60px 0;
}
.success-content {
    margin-top: 155px;
    text-align: center;
    .iconfont {
        color: #1FD18C;
        font-size: 52px;
    }
    .text {
        font-size: 24px;
        margin: 20px 0 0;
        font-weight: bold;
    }
}
.bottom-btn {
    text-align: center;
}
::v-deep .step-two-form {
    padding-left: 30px;
    .office-title {
        margin-bottom: 40px;
        .el-form-item__label {
            font-size: 16px;
            font-weight: 500;
            color: #13131B;
        }
    }
    .form-title {
        margin-top: 20px;
        color: #1121D7;
        font-size: 16px;
        display: flex;
        align-items: center;
        line-height: 1;
        padding-bottom: 12px;
        position: relative;
        .iconfont {
            margin-right: 10px;
        }
        &:after {
            content: '';
            position: absolute;
            left: 26px;
            bottom: 0;
            width: 760px;
            border-bottom: 1px solid #DBDBDB;
        }
    }
    .form-content {
        padding-left: 45px;
        & > .el-form-item {
            margin-top: 20px;
            margin-right: 110px;
            width: 120px;
        }
        &.goods-info-wrapper {
            display: flex;
            .item {
                .line-title {
                    line-height: 41px;
                    width: 70px;
                    margin-bottom: 22px;
                    text-align: right;
                    position: relative;
                    margin-right: 10px;
                    &.line-title-first {
                        width: 160px;
                        line-height: 1;
                        font-size: 16px;
                        color: #13131B;
                        height: 16px;
                        text-align: center;
                        margin: 30px 60px 20px 0;
                    }
                }
                &.item-line1 {
                    .line-title {
                        &:before {
                            content: '*';
                            color: #F56C6C;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
    .symbol-style {
        margin-left: 6px;
    }
    .wavy-line {
        line-height: 40px;
        margin: 0 7px 0 6px;
    }
    .mb40 {
        margin-bottom: 40px!important;
    }
    .mb18 {
        margin-bottom: 18px!important;
    }
}
.logo-uploader {
    width: 200px;
    height: 200px;
    border: 1px solid #ddd;
    background: #DFDFE5;
    ::v-deep .el-upload {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logo-cover {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
    .logo-uploader-icon {
        color: #999;
        font-size: 24px;
    }
}
.uploader-prompt {
    color: #F56C6C;
    white-space: nowrap;
}
.form-title-prompt {
    font-size: 14px;
    color: #999;
    margin-left: 10px;
}
::v-deep .el-input-number {
    .el-input__inner {
        text-align: left;
    }
    &.isError {
        .el-input__inner {
            border-color: #FD4446;
        }
    }
}
